import * as React from "react";
import {
  Button, Divider, Form, Grid, Header,
  Input, Segment, Container, InputOnChangeData
} from "semantic-ui-react";
import * as actions from "../../actions";
import {connect} from "react-redux";
import {
  IErrorResponse, IResetPassword
} from "../../types";
import {t} from "../../i18n";
import ScrollAnimation from 'react-animate-on-scroll';
import {toast} from "react-toastify";
import Cloud from "../backgrounds/others/Cloud";

interface StateProps {
  rPassword: IResetPassword & IErrorResponse
}

export function mapStateToProps(state: any) {
  return {
    rPassword: state.resetPassword
  };
}

interface DispatchProps {
  resetPassword: typeof actions.resetPassword
}

const mapDispatchToProps = {
  resetPassword: actions.resetPassword
}

type Props = StateProps & DispatchProps;

interface State {
  email: string,
  button: boolean,
  formErrorKeys: any,
  formErrors: any,
  showError: boolean,
  success: boolean
}

class Reset extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      button: false,
      formErrorKeys: [],
      formErrors: [],
      showError: false,
      success: false
    }
  }

  handleResetPasswordForm(e: React.FormEvent): void {
    e.preventDefault();
    this.setState({showError: false, button: true}, () => this.props.resetPassword({email: this.state.email}))
  }

  handleInputChange(e: React.ChangeEvent, data: InputOnChangeData): void {
    // @ts-ignore
    this.setState({[data.name]: data.value})
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.rPassword !== prevProps.rPassword) {
      let rPassword = this.props.rPassword;
      if (rPassword.errors) {
        for (let key in rPassword.errors) {
          toast.error(rPassword.errors[key], {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast.success("Na podany email zostały wysłane dalsze instrukcje.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        this.setState({success: true});
      }
    }
  }

  render() {
    if (this.state.success) {
      return (
        <Cloud>
          <Segment id="cloud-panel" style={{height: "100vh", display: "flex", alignItems: "center"}}>
            <Container>
              <Grid>
                <Grid.Column textAlign="center">
                  <Header id="cloud-subheader">Na podany email zostały wysłane dalsze instrukcje.</Header>
                </Grid.Column>
              </Grid>
            </Container>
          </Segment>
        </Cloud>
      )
    } else {
      return (
        <Cloud>
          <Segment basic id="cloud-panel" className="register-container">
            <Container>
              <ScrollAnimation animateIn='fadeInLeft' animateOut='fadeOutRight'>
                <div className="register-form-container">
                  <Grid stackable centered>
                    <Grid.Row>
                      <Grid.Column width={10}>
                        <Header as="h1" textAlign="center">Resetowanie hasła</Header>
                        <Divider hidden/>
                        <Form onSubmit={this.handleResetPasswordForm.bind(this)}
                              error={this.state.showError}>
                          <p>{t('e-mail')}</p>
                          <Form.Field error={this.state.formErrorKeys.email}>
                            <Input
                              placeholder={t("Email address")}
                              required
                              name="email"
                              type="email"
                              onChange={this.handleInputChange.bind(this)}
                              value={this.state.email}
                            />
                          </Form.Field>
                          <Grid textAlign='center'>
                            <Grid.Column>
                              <Button primary disabled={this.state.button} id="btn-reset-pwd" style={{marginTop: "30px"}}>
                                {t('Dalej')}
                              </Button>
                            </Grid.Column>
                          </Grid>
                        </Form>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
              </ScrollAnimation>
            </Container>
          </Segment>
        </Cloud>
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Reset);