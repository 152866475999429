import * as React from "react"
import FullApp from "../../FullApp";
import Layout from "../../templates/layout";
import Reset from "../../components/pages/passwordreset";
import { Helmet } from 'react-helmet';

export default (props) => (
  <FullApp>
    <Layout {...props} router={props} isDark={true}>
      <Helmet>
        <meta name="robots" content="noindex"/>
      </Helmet>
      <Reset />
    </Layout>
  </FullApp>
);